export default function useAlchemy() {
  const getValue = <T>(
    element: AlchemyElement,
    role: string,
  ): T | undefined => {
    const ingredient = getIngredient(element, role)
    return ingredient?.value
  }

  const getIngredient = <T extends AlchemyIngredient>(
    element: AlchemyElement,
    role: string,
  ): T | undefined => {
    const ingredient = element.ingredients.find(
      (ingredient) => ingredient.role === role,
    )
    if (ingredient) {
      return ingredient as T
    } else {
      console.warn(
        `No ingredient with role ${role} found in element ${element.name}`,
      )
      return undefined
    }
  }

  const getRichtext = (element: AlchemyElement, role: string): string => {
    const ingredient = getIngredient<AlchemyIngredientRichtext>(element, role)
    return (
      ingredient?.sanitizedBody || ingredient?.value || ingredient?.body || ""
    )
  }

  const getPageByUrlname = (
    urlname: AlchemyPage["urlname"],
    pages: AlchemyPage[],
  ) => {
    return pages.find(({ urlname: pageUrlName }) => pageUrlName === urlname)
  }

  /**
   * Takes an AlchemyElement and a map of components (mostly maped to dynamic imports) and returns the component name
   */
  const componentName = (
    element: AlchemyElement,
    components: {
      [key: string]: any
    },
  ): string => {
    const name = element?.name
    if (components[name]) {
      return components[name]
    }
    return "FallbackElement"
  }

  return {
    componentName,
    getValue,
    getIngredient,
    getRichtext,
    getPageByUrlname,
  }
}
